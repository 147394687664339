<template>
  <div>
    <Pane />

    <div class="container">
      <a-upload-dragger
        name="file"
        :multiple="true"
        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
      >
        <p class="ant-upload-drag-icon">
          <a-icon type="inbox" />
        </p>
        <p class="ant-upload-text">点击或拖拽文件上传</p>
      </a-upload-dragger>
    </div>
  </div>
</template>

<script>
import { add } from "@/api/project/income-expenditure";
import { mapGetters } from "vuex";
export default {
  name: "addDoc",

  data() {
    return {
      visible: false,
      form: this.$form.createForm(this),
      user: {},
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    costNatureList() {
      return this.findDataDict("costNature");
    },
  },

  methods: {
    addText() {
      this.visible = true;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values);
          add({
            projectId: this.projectId,
            ...values,
            operateDate: values.operateDate.format("YYYY-MM-DD"),
            operator: this.user.name,
            operatorId: this.user.userId,
          }).then(() => {
            this.form.resetFields();
            this.cancel();
            this.refreshData();
          });
        }
      });
    },
    cancel() {
      this.visible = false;
    },
  },
};
</script>


<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.center {
  margin-top: 80px;
}
</style>
